// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
import './plugins/jquery.parallax-scroll';
import './map';
//import Scrollbar from 'smooth-scrollbar';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

// When section shown in a viewport
function isInViewport(node) {
  let rect = node.getBoundingClientRect();
  return (
    (rect.height > 0 || rect.width > 0) &&
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

$(document).bind('gform_post_render', function () {
  jQuery('.gfield')
    .not('.file-upload')
    .each(function () {
      var str;
      var startStr = jQuery(this).find('input, textarea, select').val();
      if (startStr) {
        jQuery(this).find('label').addClass('is-focus');
        jQuery(this).addClass('gfield-active');
      }
      var gfield = jQuery(this);
      jQuery(this)
        .find('input, textarea, select')
        .on('input', function () {
          var input = jQuery(this);
          str = input.val();
          if (str) {
            gfield.addClass('gfield-active');
            gfield.find('label').addClass('is-focus');
          } else {
            gfield.removeClass('gfield-active');
            gfield.find('label').removeClass('is-focus');
          }
        });
    });
});

/**
 * Animate input label on different context
 */
$('body')
  .on(
    'focus',
    'input:not([type="radio"]):not([type="checkbox"]), select, textarea',
    function () {
      if ($(this).closest('.ginput_complex').length) {
        $(this).closest('span').find('label').addClass('is-focus');
      } else {
        $(this).closest('.gfield').find('label').addClass('is-focus');
      }
      $(this).closest('.acf-field').find('.acf-label').addClass('is-focus');
      $(this)
        .closest('.form-row, .pmpro_checkout-field')
        .find('label')
        .addClass('is-focus');
    }
  )
  .on(
    'blur change',
    'input:not([type="radio"]):not([type="checkbox"]), select, textarea',
    function () {
      if ($(this).val() === '') {
        if ($(this).closest('.ginput_complex').length) {
          $(this).closest('span').find('label').removeClass('is-focus');
        } else {
          $(this).closest('.gfield').find('label').removeClass('is-focus');
        }
        $(this)
          .closest('.acf-field')
          .find('.acf-label')
          .removeClass('is-focus');
        $(this)
          .closest('.form-row, .pmpro_checkout-field')
          .find('label')
          .removeClass('is-focus');
      } else {
        if ($(this).closest('.ginput_complex').length) {
          $(this).closest('span').find('label').addClass('is-focus');
        } else {
          $(this).closest('.gfield').find('label').addClass('is-focus');
        }
        $(this).closest('.acf-field').find('.acf-label').addClass('is-focus');
        $(this)
          .closest('.form-row, .pmpro_checkout-field')
          .find('label')
          .addClass('is-focus');
      }
    }
  );

/**
 * Prevent Body Scrolling With iOS support
 */
let $html = $('html');
let offset;

function preventBodyScrolling() {
  if (!$html.hasClass('is-menu-open')) {
    offset = $(window).scrollTop();
    $html.data('scroll-offset', offset);
    $html.addClass('is-menu-open');
  } else {
    $html.removeClass('is-menu-open');
    offset = $html.data('scroll-offset');
    $(window).scrollTop(offset);
  }
}

// // When section shown in a viewport
// function isInViewport(node) {
//   let rect = node.getBoundingClientRect();
//   return (
//     (rect.height > 0 || rect.width > 0) &&
//     rect.bottom >= 0 &&
//     rect.right >= 0 &&
//     rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.left <= (window.innerWidth || document.documentElement.clientWidth)
//   );
// }

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  // // eslint-disable-next-line no-unused-vars
  // const scrollbar = Scrollbar.init(document.querySelector('.home__wrapper'), {
  //   // Momentum reduction damping factor, a float value between (0, 1)
  //   damping: 0.01,
  //   alwaysShowTracks: false,
  //   continuousScrolling: false,
  // });
  //
  // $('.home__wrapper').on('scroll', function () {
  //   var scrolledEl = $(document).find('.animation');
  //   $(scrolledEl).each(function () {
  //     var visible = isInViewport(this);
  //     if (visible) {
  //       $(this).attr('data-scroll', 'in');
  //     }
  //   });
  // });
  //
  // //eslint-disable-next-line no-unused-vars
  // scrollbar.addListener((status) => {
  //   $(window).trigger('scroll');
  //   if ($('.home__wrapper').trigger('scroll')) {
  //     // ScrollOut.update();
  //   }
  // });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    once: true,
    threshold: 0.01,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Sliders
   */

  $('.properties__wrapper-js').slick({
    //cssEase: 'ease',
    arrows: true,
    dots: false,
    //infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '150px',
    slidesToScroll: 1,
    variableWidth: true,
    touchThreshold: 14,
    rows: 0, // Prevent generating extra markup
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerPadding: 0,
        },
      },
    ],
  });

  $('.properties-s__wrapper-js').slick({
    //cssEase: 'ease',
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    touchThreshold: 14,
    slidesPerRow: 3,
    rows: 3, // Prevent generating extra markup
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesPerRow: 2,
          rows: 3, // Prevent generating extra markup
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesPerRow: 1,
          rows: 5, // Prevent generating extra markup
        },
      },
    ],
  });

  $('.property__gallery-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.property__gallery-nav',
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    touchThreshold: 14,
    rows: 0, // Prevent generating extra markup
    nextArrow: '.next-image',
    prevArrow: '.prev-image',
  });

  $('.property__gallery-nav').slick({
    slidesToShow: 7,
    slidesToScroll: 1,
    asNavFor: '.property__gallery-for',
    dots: false,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    touchThreshold: 14,
    variableWidth: true,
    rows: 0, // Prevent generating extra markup
  });

  if ($(window).width() > 1024) {
    $('.home-services').each(function () {
      var visible = isInViewport(this);
      if (visible) {
        $('.section__content').attr('data-scroll', 'in');
        $('.services__item').attr('data-scroll', 'in');
        $('.decorative-dots').attr('data-scroll', 'in');
      }
    });
  }
  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 350 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      preventBodyScrolling();
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $html.removeClass('is-menu-open');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here

  if (!$html.hasClass('is-menu-open')) {
    if ($(window).scrollTop() > 15) {
      $('.header').addClass('is-stuck');
    }
    if ($(window).scrollTop() < 15) {
      $('.header').removeClass('is-stuck');
    }
  }
});
